<template>
  <div anchor-app-extra>
    <vue-snotify></vue-snotify>
    <anchor-popup style="z-index: 204 !important;"></anchor-popup>

    <!--loader-->
    <v-overlay :value="loading" :style="loadingStyle">
      <v-row>
        <v-col justing="center" align="center">
          <v-progress-circular indeterminate size="80"></v-progress-circular>
        </v-col>
      </v-row>
      <div class="mt-8 text-h5" v-if="loadingText">
        {{ loadingText }}
      </div>
    </v-overlay>

    <bottomNavigation></bottomNavigation>
    <bottomSheet></bottomSheet>
  </div>
</template>

<script lang="babel" type="text/babel">
import Vue from 'vue'
import AnchorPopup from 'plugins/anchorPopup/installer.js'
import Snotify from 'vue-snotify'
Vue.use(Snotify, {
  toast: { timeout: 5000, titleMaxLength: 32 },
})
export default {
  data: () => ({
    hasInit: false,
  }),
  created() {
    Vue.use(AnchorPopup, this.anchorPopupDefaultConfig)
    this.init()
  },
  methods: {
    async init() {
      this.hasInit = false

      const { default: VueClipboard } = await import(
        /* webpackChunkName: "vue-clipboard2" */
        /* webpackPrefetch: true */
        'vue-clipboard2'
      )
      Vue.use(VueClipboard)

      setTimeout(() => {
        this.hasInit = true
      }, 1000)
    },
  },
  computed: {
    loadingStyle() {
      return {
        zIndex: 999999999,
      }
    },
    anchorPopupDefaultConfig() {
      return {
        width: '350',
        applyLabel: this.i18n ? this.i18n('action.apply') : '確定',
        closeLabel: this.i18n ? this.i18n('action.close') : '關閉',
      }
    },
    loading() {
      return this.$store.getters['base/loading']
    },
    loadingText() {
      return this.$store.getters['base/loadingText']
    },
    language() {
      return this.$store.getters['locale/language']
    },
    layout() {
      return this.$store.getters['base/layout']
    },
    isAdmin() {
      return this.layout === 'admin'
    },
  },
  watch: {
    isAdmin() {
      this.$apopup.init(this.anchorPopupDefaultConfig)
    },
  },
  components: {
    bottomSheet: () => import(
      /* webpackChunkName: "bottomSheet" */
      /* webpackPrefetch: true */
      'appExtra/bottomSheet.vue'
    ),
    bottomNavigation: () => import(
      /* webpackChunkName: "bottomNavigation" */
      /* webpackPrefetch: true */
      'appExtra/bottomNavigation.vue'
    ),
  },
}
</script>

<style lang="sass" type="text/sass">
@import '~vue-snotify/styles/material'

// 處理tinymce在vuetify dialog無法啟用tinymce dialog的問題
div[anchor-bottom-sheet]
  div[role="document"].v-dialog__content
    z-index: 203 !important
</style>